.social_icons {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 5rem;
    background-color: white;
    max-width: fit-content;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    padding: 0.25rem 0.7rem;
}

.icon img{
    max-height: 2.5rem;
    height: auto;
}