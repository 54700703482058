:root {
  --dark: #1e1e1e;
  --darker: #181818;
  --text-color: #ffffff90;
  --blue: #2CB3FF;

  font-size: 62.5%;
}

body {
  padding: 0 15vw;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: var(--dark);
  color: var(--text-color);
  
  font-size: 1.6rem;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  font-family: "Six Caps", sans-serif;
  font-weight: 400;
  font-size: 6rem;
}

h2 {
  font-family: "Six Caps", sans-serif;
  font-weight: 400;
  font-size: 5rem;
}

li {
  list-style: none;
}
